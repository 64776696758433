"use strict";

require("core-js/modules/es.object.define-property");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = useTracking;

var _react = require("react");

var _withTrackingComponentDecorator = require("./withTrackingComponentDecorator");

function useTracking() {
  var trackingContext = (0, _react.useContext)(_withTrackingComponentDecorator.ReactTrackingContext);

  if (!(trackingContext && trackingContext.tracking)) {
    throw new Error('Attempting to call `useTracking` ' + 'without a ReactTrackingContext present. Did you forget to wrap the top of ' + 'your component tree with `track`?');
  }

  return (0, _react.useMemo)(function () {
    return {
      getTrackingData: trackingContext.tracking.getTrackingData,
      trackEvent: trackingContext.tracking.dispatch
    };
  }, [trackingContext.tracking.getTrackingData, trackingContext.tracking.dispatch]);
}