"use strict";

require("core-js/modules/es.function.bind");

require("core-js/modules/es.object.define-property");

require("core-js/modules/es.reflect.apply");

require("core-js/modules/es.reflect.define-property");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = makeClassMemberDecorator;

/**
 * Helper to decorate class member
 * Supports class plain methods, field syntax and lazy methods
 * @param {Function} decorate Actual decorator function.
 * Example:
 *   decoratedFn => function () {
 *     // do stuff...
 *     return Reflect.apply(decoratedFn, this, arguments);
 *   }
 * @returns {Function} Class member decorator ((target, name, descriptor) => newDescriptor)
 */
function makeClassMemberDecorator(decorate) {
  return function decorateClassMember(target, name, descriptor) {
    var configurable = descriptor.configurable,
        enumerable = descriptor.enumerable,
        value = descriptor.value,
        _get = descriptor.get,
        initializer = descriptor.initializer;

    if (value) {
      return {
        configurable: configurable,
        enumerable: enumerable,
        value: decorate(value)
      };
    } // support lazy initializer


    if (_get || initializer) {
      return {
        configurable: configurable,
        enumerable: enumerable,
        get: function get() {
          // This happens if someone accesses the
          // property directly on the prototype
          if (this === target) {
            return null;
          }

          var resolvedValue = initializer ? Reflect.apply(initializer, this, []) : Reflect.apply(_get, this, []);
          var decoratedValue = decorate(resolvedValue).bind(this);
          Reflect.defineProperty(this, name, {
            configurable: configurable,
            enumerable: enumerable,
            value: decoratedValue
          });
          return decoratedValue;
        }
      };
    }

    throw new Error('called makeClassMemberDecorator on unsupported descriptor');
  };
}