"use strict";

require("core-js/modules/es.object.define-property");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = hoc;

var _withTrackingComponentDecorator = _interopRequireDefault(require("./withTrackingComponentDecorator"));

var _trackEventMethodDecorator = _interopRequireDefault(require("./trackEventMethodDecorator"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function hoc(trackingInfo, options) {
  return function decorator() {
    if (arguments.length === 1) {
      // decorating a class
      return (0, _withTrackingComponentDecorator["default"])(trackingInfo, options).apply(void 0, arguments);
    } // decorating a method


    return (0, _trackEventMethodDecorator["default"])(trackingInfo).apply(void 0, arguments);
  };
}