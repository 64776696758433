"use strict";

require("core-js/modules/es.function.bind");

require("core-js/modules/es.object.define-property");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.promise");

require("core-js/modules/es.reflect.apply");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = trackEventMethodDecorator;

var _makeClassMemberDecorator = _interopRequireDefault(require("./makeClassMemberDecorator"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function trackEventMethodDecorator() {
  var trackingData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _makeClassMemberDecorator["default"])(function (decoratedFn) {
    return function decorateClassMember() {
      var _this = this;

      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      var trackEvent = function trackEvent() {
        if (_this.props && _this.props.tracking && typeof _this.props.tracking.trackEvent === 'function') {
          for (var _len2 = arguments.length, promiseArguments = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
            promiseArguments[_key2] = arguments[_key2];
          }

          var thisTrackingData = typeof trackingData === 'function' ? trackingData(_this.props, _this.state, args, promiseArguments) : trackingData;

          if (thisTrackingData) {
            _this.props.tracking.trackEvent(thisTrackingData);
          }
        }
      };

      var fn = Reflect.apply(decoratedFn, this, args);

      if (Promise && Promise.resolve(fn) === fn) {
        return fn.then(trackEvent.bind(this)).then(function () {
          return fn;
        })["catch"](function (error) {
          trackEvent({}, error);
          throw error;
        });
      }

      trackEvent();
      return fn;
    };
  });
}