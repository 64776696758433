import { create } from 'jss';
import preset from 'jss-preset-default';

// I have been trying to benchmark and I have seen a slow down after about 10k rules.
// Since we are in a single sheet mode, user shouldn't care about this.
var MAX_RULES_PER_SHEET = 10000;
var defaultJss = create(preset());

var createCss = function createCss(jss) {
  if (jss === void 0) {
    jss = defaultJss;
  }

  var cache = new Map();
  var ruleIndex = 0;
  var sheet;

  var getSheet = function getSheet() {
    if (!sheet || sheet.rules.index.length > MAX_RULES_PER_SHEET) {
      sheet = jss.createStyleSheet().attach();
    }

    return sheet;
  };

  function css() {
    // eslint-disable-next-line prefer-rest-params
    var args = arguments; // We can avoid the need for stringification with a babel plugin,
    // which could generate a hash at build time and add it to the object.

    var argsStr = JSON.stringify(args);
    var cached = cache.get(argsStr);
    if (cached) return cached.className;
    var flatArgs = []; // Flatten arguments which can be
    // - style objects
    // - array of style objects
    // - arrays of style objects

    for (var argIndex in args) {
      var arg = args[argIndex];

      if (!Array.isArray(arg)) {
        flatArgs.push(arg);
        continue;
      }

      for (var innerArgIndex = 0; innerArgIndex < arg.length; innerArgIndex++) {
        flatArgs.push(arg[innerArgIndex]);
      }
    }

    var mergedStyle = {};
    var labels = [];

    for (var i = 0; i < flatArgs.length; i++) {
      var style = flatArgs[i];
      if (!style) continue; // It can be a class name that css() has previously generated.

      if (typeof style === 'string') {
        // eslint-disable-next-line no-shadow
        var _cached = cache.get(style);

        if (_cached) {
          // eslint-disable-next-line prefer-spread
          if (_cached.labels.length) labels.push.apply(labels, _cached.labels);
          style = _cached.style;
        }
      }

      if (style.label && labels.indexOf(style.label) === -1) labels.push(style.label);
      Object.assign(mergedStyle, style);
    }

    delete mergedStyle.label;
    var label = labels.length === 0 ? 'css' : labels.join('-');
    var key = label + "-" + ruleIndex++;
    getSheet().addRule(key, mergedStyle);
    var className = getSheet().classes[key];
    var cacheValue = {
      style: mergedStyle,
      labels: labels,
      className: className
    };
    cache.set(argsStr, cacheValue);
    cache.set(className, cacheValue);
    return className;
  } // For testing only.


  css.getSheet = getSheet;
  return css;
};

var index = createCss();

export default index;
export { createCss as create };
