"use strict";

require("core-js/modules/es.array.iterator");

require("core-js/modules/es.function.name");

require("core-js/modules/es.object.assign");

require("core-js/modules/es.object.define-property");

require("core-js/modules/es.object.get-own-property-descriptor");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.string.iterator");

require("core-js/modules/es.weak-map");

require("core-js/modules/web.dom-collections.iterator");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = withTrackingComponentDecorator;
exports.ReactTrackingContext = void 0;

var _react = _interopRequireWildcard(require("react"));

var _deepmerge = _interopRequireDefault(require("deepmerge"));

var _hoistNonReactStatics = _interopRequireDefault(require("hoist-non-react-statics"));

var _dispatchTrackingEvent = _interopRequireDefault(require("./dispatchTrackingEvent"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; if (obj != null) { var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var ReactTrackingContext = _react["default"].createContext({});

exports.ReactTrackingContext = ReactTrackingContext;

function withTrackingComponentDecorator() {
  var trackingData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref$dispatch = _ref.dispatch,
      dispatch = _ref$dispatch === void 0 ? _dispatchTrackingEvent["default"] : _ref$dispatch,
      _ref$dispatchOnMount = _ref.dispatchOnMount,
      dispatchOnMount = _ref$dispatchOnMount === void 0 ? false : _ref$dispatchOnMount,
      process = _ref.process;

  return function (DecoratedComponent) {
    var decoratedComponentName = DecoratedComponent.displayName || DecoratedComponent.name || 'Component';

    function WithTracking(props) {
      var _useContext = (0, _react.useContext)(ReactTrackingContext),
          tracking = _useContext.tracking;

      var latestProps = (0, _react.useRef)(props);
      (0, _react.useEffect)(function () {
        // keep the latest props in a mutable ref object to avoid creating
        // additional dependency that could cause unnecessary re-renders
        // see https://reactjs.org/docs/hooks-faq.html#what-can-i-do-if-my-effect-dependencies-change-too-often
        latestProps.current = props;
      }); // statically extract tracking.process for hook dependency

      var trkProcess = tracking && tracking.process;
      var getProcessFn = (0, _react.useCallback)(function () {
        return trkProcess;
      }, [trkProcess]);
      var getOwnTrackingData = (0, _react.useCallback)(function () {
        var ownTrackingData = typeof trackingData === 'function' ? trackingData(latestProps.current) : trackingData;
        return ownTrackingData || {};
      }, []);
      var getTrackingDataFn = (0, _react.useCallback)(function () {
        var contextGetTrackingData = tracking && tracking.getTrackingData || getOwnTrackingData;
        return function () {
          return contextGetTrackingData === getOwnTrackingData ? getOwnTrackingData() : (0, _deepmerge["default"])(contextGetTrackingData(), getOwnTrackingData());
        };
      }, [getOwnTrackingData, tracking]);
      var getTrackingDispatcher = (0, _react.useCallback)(function () {
        var contextDispatch = tracking && tracking.dispatch || dispatch;
        return function (data) {
          return contextDispatch((0, _deepmerge["default"])(getOwnTrackingData(), data || {}));
        };
      }, [getOwnTrackingData, tracking]);
      var trackEvent = (0, _react.useCallback)(function () {
        var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        getTrackingDispatcher()(data);
      }, [getTrackingDispatcher]);
      (0, _react.useEffect)(function () {
        var contextProcess = getProcessFn();
        var getTrackingData = getTrackingDataFn();

        if (getProcessFn() && process) {
          // eslint-disable-next-line
          console.error('[react-tracking] options.process should be defined once on a top-level component');
        }

        if (typeof contextProcess === 'function' && typeof dispatchOnMount === 'function') {
          trackEvent((0, _deepmerge["default"])(contextProcess(getOwnTrackingData()) || {}, dispatchOnMount(getTrackingData()) || {}));
        } else if (typeof contextProcess === 'function') {
          var processed = contextProcess(getOwnTrackingData());

          if (processed || dispatchOnMount === true) {
            trackEvent(processed);
          }
        } else if (typeof dispatchOnMount === 'function') {
          trackEvent(dispatchOnMount(getTrackingData()));
        } else if (dispatchOnMount === true) {
          trackEvent();
        }
      }, [getOwnTrackingData, getProcessFn, getTrackingDataFn, trackEvent]);
      var trackingProp = (0, _react.useMemo)(function () {
        return {
          trackEvent: trackEvent,
          getTrackingData: getTrackingDataFn()
        };
      }, [trackEvent, getTrackingDataFn]);
      var contextValue = (0, _react.useMemo)(function () {
        return {
          tracking: {
            dispatch: getTrackingDispatcher(),
            getTrackingData: getTrackingDataFn(),
            process: getProcessFn() || process
          }
        };
      }, [getTrackingDispatcher, getTrackingDataFn, getProcessFn]);
      return (0, _react.useMemo)(function () {
        return _react["default"].createElement(ReactTrackingContext.Provider, {
          value: contextValue
        }, _react["default"].createElement(DecoratedComponent, _extends({}, props, {
          tracking: trackingProp
        })));
      }, [contextValue, props, trackingProp]);
    }

    WithTracking.displayName = "WithTracking(".concat(decoratedComponentName, ")");
    (0, _hoistNonReactStatics["default"])(WithTracking, DecoratedComponent);
    return WithTracking;
  };
}